import { Router, NavigationEnd } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
// import { ROUTES } from "./sidebar-items";
import { AuthService } from "src/app/core/service/auth.service";
import { environment } from "src/environments/environment";
import { AllRoutesService } from "./sidebar-items.service";
import { DropDownList } from "src/app/DTOs/DropDownList/dropDownList";
import { TranslateService } from "@ngx-translate/core";
import { AutoComplete } from "primeng/autocomplete";
import { PermissionService } from "src/app/core/service/permission.service";
import { ScrollPanel } from "primeng/scrollpanel";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.sass"],
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autoComplete') autoComplete: AutoComplete;
  @ViewChild('scrollPanel', { static: true }) sidebarscroll: ElementRef;
  @ViewChild('scrollPanel') scrollPanel: ScrollPanel;
  public sidebarItems: any[];
  level1Menu = "";
  level2Menu = "";
  level3Menu = "";
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;
  routerlink: string[];
  searchvalue: string;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private allRoutesService: AllRoutesService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public permissionService: PermissionService,
  ) {
    const body = this.elementRef.nativeElement.closest("body");
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const currenturl = event.url.split("?")[0];
        this.routerlink = currenturl.split("/");
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, "overlay-open");
      }
    });
  }
  ngAfterViewInit(): void {
    this.makeSidebarActive();
  }

  makeSidebarActive() {
    // if (this.routerlink.length > 0) {
    //   if (this.routerlink.length >= 1) {
    //     if (this.routerlink[2] === this.level1Menu) {
    //       this.level1Menu = "0";
    //     } else {
    //       this.level1Menu = this.routerlink[2];
    //     }
    //   }
    //   if (this.routerlink.length >= 2)
    //     this.callLevel2Toggle('', this.routerlink[3]);
    //   if (this.routerlink.length >= 3)
    //     this.callLevel3Toggle('', this.routerlink[4]);
    if (this.routerlink.length >= 1) {
      this.level1Menu = this.routerlink[2];
      this.level2Menu = this.routerlink[3];
      this.level3Menu = this.routerlink[4];
      console.log(this.routerlink);
      console.log(this.level1Menu);
      console.log(this.level2Menu);
      console.log(this.level3Menu);
      this.cdr.detectChanges();
      if (this.level1Menu && this.level1Menu != undefined && this.level1Menu != 'profile')
        if (this.level2Menu && this.level3Menu)
          this.scrollToItem(this.level2Menu);
        else
          this.scrollToItem(this.level1Menu);
    }
  }

  // scrollToElement(targetElem: string) {
  //   const targetElement = $('#' + targetElem);
  //   const targetOffset = targetElement.offset().top - 70;
  //   $('.sidebar .menu').animate({ scrollTop: targetOffset }, 500);
  // }

  scrollToItem(targetElem) {
    const activeElement = this.scrollPanel.containerViewChild.nativeElement.querySelector(`#${targetElem}`);
    console.log(activeElement);
    if (activeElement) {
      console.log(activeElement.offsetTop - 121);
      this.scrollPanel.scrollTop(activeElement.offsetTop - 110);
    }
  }

  onImgError(event) {
    event.target.src = 'assets/images/user/usrbig6.jpg';
  }

  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, "overlay-open");
    }
  }

  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = "0";
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains("toggled");
    if (hasClass) {
      this.renderer.removeClass(event.target, "toggled");
    } else {
      this.renderer.addClass(event.target, "toggled");
    }
  }

  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = "0";
    } else {
      this.level2Menu = element;
    }
  }

  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = "0";
    } else {
      this.level3Menu = element;
    }
  }

  selectedsidebar: any;
  filteredSidebar: any[];
  sidebar: Array<DropDownList> = [];
  ngOnInit() {
    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.role;
      this.userFullName = localStorage.getItem("username");
      this.userImg = localStorage.getItem("imageUrl");
      // this.sidebarItems = this.allRoutesService.ROUTES.filter(
      //   (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf("All") !== -1
      // );
      this.sidebarItems = this.allRoutesService.getroutes();
      this.userType = localStorage.getItem('role');
    }
    this.initLeftSidebar();
    this.bodyTag = this.document.body;

    this.sidebarItems.map(sidebar => {
      if (sidebar.class === '' && sidebar.displayPermission === true)
        this.sidebar.push({ value: sidebar.path, text: sidebar.title });
      if (sidebar.submenu.length > 0) {
        sidebar.submenu.map(subsidebar => {
          if (subsidebar.path !== '' && subsidebar.displayPermission === true)
            this.sidebar.push({ value: subsidebar.path, text: subsidebar.title });
          if (subsidebar.submenu.length > 0) {
            subsidebar.submenu.map(subsubsidebar => {
              if (subsubsidebar.path !== '' && subsubsidebar.displayPermission === true)
                this.sidebar.push({ value: subsubsidebar.path, text: subsubsidebar.title });
            });
          }
        });
      }
    });
  }

  filterSide(event) {
    let query = event.query;
    this.searchvalue = query;
    if (this.hasArabicCodepoints(query) == true)
      this.filteredSidebar = this.sidebar.filter(option => this.translate.instant(option.text).includes(query));
    else
      // this.filteredSidebar = this.sidebar.filter(option => option.text.toLowerCase().includes(query.toLowerCase()));
      this.filteredSidebar = this.sidebar.filter(option => this.containsLetters(this.translate.instant(option.text), query));
  }

  highlightMatch(name: string): string {
    const searchTerm = this.searchvalue;
    if (searchTerm && name) {
      const regex = new RegExp(searchTerm, 'gi');
      const highlightedText = name.replace(regex, '<span class="highlight-autoComplate">$&</span>');
      return highlightedText;
    }
    return name;
  }

  containsLetters(name: string, searchTerm: string): boolean {
    const lowercaseName = name.toLowerCase();
    const lowercaseSearchTerm = searchTerm.toLowerCase();
    return lowercaseName.includes(lowercaseSearchTerm);
  }

  selected() {
    this.router.navigate([this.selectedsidebar.value]);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currenturl = event.url.split("?")[0];
        this.routerlink = currenturl.split("/");
        console.log(this.routerlink);
        this.makeSidebarActive();
      }
    });
    this.selectedsidebar = '';
  }

  hasArabicCodepoints(s) {
    return /[\u0600-\u06FF]/.test(s);
  }

  // private _filter(value: string): DropDownList[] {
  //   const filterValue = value.toLowerCase();
  //   return this.sidebar.filter(option => option.text.toLowerCase().includes(filterValue));
  // }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }

  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }
  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, "ls-closed");
    } else {
      this.renderer.removeClass(this.document.body, "ls-closed");
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("submenu-closed")) {
      this.renderer.addClass(this.document.body, "side-closed-hover");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("side-closed-hover")) {
      this.renderer.removeClass(this.document.body, "side-closed-hover");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["/authentication/signin"]);
      }
    });
  }

  // isActiveMenuItem(route: string): boolean {
  //   return this.router.isActive(route, true);
  // }

  isActiveSubMenuItem(moduleName: string): boolean {
    var a = this.routerlink.find(a => a == moduleName);
    if (a)
      return true;
    else return false;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey) {
      if (event.key === 'g' || event.key === 'G') {
        this.autoComplete.focusInput();
      }
    }
  }
}
