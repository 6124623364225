import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/config/config.service";
import { AuthService } from "src/app/core/service/auth.service";
import { RightSidebarService } from "src/app/core/service/rightsidebar.service";
import { LanguageService } from "src/app/core/service/language.service";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { DropDownList } from "src/app/DTOs/DropDownList/dropDownList";
import { AllRoutesService } from "../sidebar/sidebar-items.service";
import { debounceTime } from "rxjs";
const document: any = window.document;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.sass"],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit, OnDestroy {
  public config: any = {};
  userImg: string;
  homePage: string;
  isNavbarCollapsed = true;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  Attended: string;
  BranchDropDown: DropDownList[];
  Selectedbranch: DropDownList;
  notifications: any[] = [];
  allnotifications: any[] = [];
  unreadednotification: number = 0;
  notificationFilter: number = 0;
  userName: string;
  userType: string;
  filtered: any[] = [];
  filtervalue:string;
  loading: boolean = false;
  sidebar: any[] = [];
  sidebar2: any[] = [];
  selectedItme: any;
  timeoutId;
  searchvalue: string;
  searchvisible = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public languageService: LanguageService,
    private allRoutesService: AllRoutesService,
  ) {
    super();
    this.userName = localStorage.getItem('username');
    this.userType = localStorage.getItem('role');
    this.authService.startConnection();
    // this.authService.getNotifications().subscribe(notifications=>{
    //   console.log(notifications);
    // });
    // this.authService.notifications.subscribe(
    //   (data: number) => {
    //     this.quantity = data;
    //     $('.quantity').text(this.quantity)
    //   });

    // this.authService.getNotifications().subscribe(notifications => {
    //   notifications.data.map(notification => {
    //     this.notifications.push(notification);
    //     this.allnotifications.push(notification);
    //   });
    //   this.unreadednotification = this.notifications.filter(item => item.read == false).length;
    // });

    // this.authService.getNotificationSubject().subscribe(message => {
    //   this.notifications = [message, ...this.notifications];
    //   this.allnotifications = [message, ...this.allnotifications];
    //   this.unreadednotification = this.notifications.filter(item => item.read == false).length;
    // });
  }

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Arabic", flag: "assets/images/flags/ar.jpg", lang: "ar" }
  ];

  gotoNotificationDetails(id) {
    this.close();
    this.router.navigate(['/currentuser/mynotificationdetails/' + id]);
  }

  notificationFilterChanged(filter: number) {
    this.notificationFilter = filter;
    if (filter == 0)
      this.notifications = this.allnotifications;
    else if (filter == 1)
      this.notifications = this.allnotifications.filter(a => a.read == true);
    else if (filter == 2)
      this.notifications = this.allnotifications.filter(a => a.ignore == true);
  }
  close() {
    $(".notification-icon").click();
  }
  //  [
  //     {
  //       userImg: "assets/images/user/user1.jpg",
  //       userName: "Tamer Ali",
  //       time: "14 mins ago",
  //       message: "Please check your mail",
  //     },
  //     {
  //       userImg: "assets/images/user/user2.jpg",
  //       userName: "Airi Satou",
  //       time: "22 mins ago",
  //       message: "Work Completed !!!",
  //     },
  //     {
  //       userImg: "assets/images/user/user3.jpg",
  //       userName: "John Doe",
  //       time: "3 hours ago",
  //       message: "kindly help me for code.",
  //     },
  //     {
  //       userImg: "assets/images/user/user4.jpg",
  //       userName: "Ashton Cox",
  //       time: "5 hours ago",
  //       message: "Lets break for lunch...",
  //     },
  //     {
  //       userImg: "assets/images/user/user5.jpg",
  //       userName: "Tamer Ali",
  //       time: "14 mins ago",
  //       message: "Please check your mail",
  //     },
  //     {
  //       userImg: "assets/images/user/user6.jpg",
  //       userName: "Airi Satou",
  //       time: "22 mins ago",
  //       message: "Work Completed !!!",
  //     },
  //     {
  //       userImg: "assets/images/user/user7.jpg",
  //       userName: "John Doe",
  //       time: "3 hours ago",
  //       message: "kindly help me for code.",
  //     },
  //   ];

  onImgError(event) {
    event.target.src = 'assets/images/user/usrbig6.jpg';
  }

  ngOnInit() {
    this.BranchDropDown = JSON.parse(localStorage.getItem('branches')).map(data => {
      return <DropDownList>{
        text: data.branchName,
        value: data.branchId,
      }
    });
    if (localStorage.getItem('branchId') != '') {
      var a = this.BranchDropDown.filter(a => a.value.toUpperCase() == localStorage.getItem('branchId').toUpperCase()).map(n => {
        return <DropDownList>{
          text: n.text,
          value: n.value
        }
      });
      this.Selectedbranch = a[0];
    }
    else {
      localStorage.setItem('branchId', this.Selectedbranch.value);
      this.Selectedbranch = this.BranchDropDown[0];
    }

    this.config = this.configService.configData;
    const userRole = this.authService.currentUserValue.role;
    //this.userImg = this.authService.currentUserValue.imageUrl;
    this.userImg = localStorage.getItem("imageUrl");

    if (userRole === "Admin") {
      this.homePage = "admin/dashboard/main";
    } else if (userRole === "Patient") {
      this.homePage = "patient/dashboard";
    } else if (userRole === "Doctor") {
      this.homePage = "doctor/dashboard";
    } else {
      this.homePage = "admin/dashboard/main";
    }

    this.langStoreValue = localStorage.getItem("lang");
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    this.allRoutesService.getroutes().map(sidebar => {
      var item = {
        title: '',
        items: []
      };
      if (sidebar.displayPermission === true)
        item.title = sidebar.title;
      if (sidebar.submenu.length > 0) {
        sidebar.submenu.map(subsidebar => {
          if (subsidebar.submenu.length > 0) {
            subsidebar.submenu.map(subsubsidebar => {
              if (subsubsidebar.path.includes('add') && subsubsidebar.displayPermission === true)
                item.items.push({ value: subsubsidebar.path, text: subsubsidebar.title });
            });
          }
          else {
            if (subsidebar.path.includes('add'))
              item.items.push({ value: subsidebar.path, text: subsidebar.title });
          }
        });
      }
      if (item.items.length > 0)
        this.sidebar.push(item);
    });
  }


  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem("theme")) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem("theme"));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem("menuOption")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("menuOption")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "menu_" + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem("choose_logoheader")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_logoheader")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "logo-" + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem("sidebar_status")) {
      if (localStorage.getItem("sidebar_status") === "close") {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      }
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
    this.switchDirection(lang);
    window.location.reload();
  }

  setbranch(value: string, text: string) {
    this.Selectedbranch = {
      value: value,
      text: text
    };
    localStorage.setItem('branchId', this.Selectedbranch.value);
  }

  selected() {
    this.selectedItme = null;
  }

  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains("side-closed");
    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
  public toggleRightSidebar(): void {
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSidebar = isRunning;
      }
    );

    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }

  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["/authentication/signin"]);
      }
    });
  }


  highlightMatch(name: string): string {
    const searchTerm = this.searchvalue;
    if (searchTerm && name) {
      const regex = new RegExp(searchTerm, 'gi');
      const highlightedText = name.replace(regex, '<span class="highlight-autoComplate">$&</span>');
      return highlightedText;
    }
    return name;
  }


  switchDirection(lang: string) {
    var isrtl: string = localStorage.getItem("isRtl");
    if (isrtl === "true" && document.getElementsByTagName("html")[0].hasAttribute("dir") && lang === "en") {
      localStorage.setItem("isRtl", "false");
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      this.renderer.removeClass(this.document.body, "rtl");
    } else if (isrtl === "false" && !document.getElementsByTagName("html")[0].hasAttribute("dir") && lang === "ar") {
      localStorage.setItem("isRtl", "true");
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      this.renderer.addClass(this.document.body, "rtl");
    }
  }

  showNotification(colorName, text, placementFrom, placementAlign, duration) {
    this.snackBar.open(text, "", {
      duration: duration,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  ngOnDestroy() {
    this.authService.stopConnection();
  }

  // filterSide(event) {
  //   let query = event.query;
  //   this.allRoutesService.getSearchFromApi(query).subscribe(res => {
  //     this.filtered = this.groupByEntityName(res.data);
  //     console.log(this.filtered);
  //   });
  // }


  filterSide(event) {
    this.filtered = null;
    let query = event.query;
    this.searchvalue = query;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.allRoutesService.getSearchFromApi(query).subscribe(res => {
        this.filtered = this.groupByEntityName(res.data);
        console.log(this.filtered);
      });
    }, 1000);
  }

  filterSidebar(event) {
    this.filtered = null;
    this.loading = true;
    let query = event.target.value;
    this.searchvalue = query;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.allRoutesService.getSearchFromApi(query).subscribe(res => {
        this.filtered = this.groupByEntityName(res.data);
        this.loading = false;
      }), err => {
        this.loading = false;
      };
    }, 1000);
  }

  onHideFilter() {
    this.selectedItme = null;
  }

  groupByEntityName(items: any[]): { key: string, items: any[] }[] {
    return items.reduce((groups, item) => {
      const { entityName } = item;
      const group = groups.find(g => g.key === entityName);
      if (group) {
        group.items.push(item);
      } else {
        groups.push({ key: entityName, items: [item] });
      }
      return groups;
    }, []);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey) {
      if (event.key === ' ') {
        this.searchvisible = true;
      }
    }
  }
}
