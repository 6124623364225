import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DropDownList } from 'src/app/DTOs/DropDownList/dropDownList';

@Component({
  selector: 'app-report-column-form',
  template: ` <div *ngFor="let column of columns">
    <div class="reportColumn" [ngStyle]="column.columns.length>0?{padding: '10px 0px 51px 0px'}:''">
      <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                    <label class="requird">{{'COMMON.Name' | translate}}
                      <span>*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>{{ 'COMMON.Name' | translate }}</mat-label>
                      <input matInput [(ngModel)]="column.columnName" required>
                      <mat-icon matPrefix>flag</mat-icon>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-2">
                    <label class="requird">{{'ReportBuilder.DisplayName' | translate}}
                      <span>*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>{{ 'ReportBuilder.DisplayName' | translate }}</mat-label>
                      <input matInput [(ngModel)]="column.displayName" required>
                      <mat-icon matPrefix>flag</mat-icon>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-2">
                    <label class="requird">{{'COMMON.Name' | translate}}
                      <span>*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>{{ 'COMMON.Name' | translate }}</mat-label>
                      <input matInput [(ngModel)]="column.type" required>
                      <mat-icon matPrefix>flag</mat-icon>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-2">
                    <p style="margin: 1px 0px;" class="optional">{{'ReportBuilder.Width' | translate}}</p>
                    <p-inputNumber [showButtons]="true" [(ngModel)]="column.width"
                      buttonLayout="horizontal" inputId="horizontal" spinnerMode="horizontal"
                      [step]="1" [min]="1" [max]="100" decrementButtonClass="p-button-secondary"
                      incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus" class="inputNumber">
                  </p-inputNumber>
                  </div>

                  <div class="buttons">
                    <button class="btn-space addDayButton button" type="button" mat-raised-button color="primary" (click)="addChild(column)">Add Child {{column.displayorder}}</button>
                    <mat-icon fontSet="material-icons-outlined" class="mat-icon-in-button mx-3" (click)="removeColumn(column)">delete</mat-icon>
                  </div>
      </div>
      <app-report-column-form [columns]="column.columns"></app-report-column-form>
    </div>
 </div>
  `,
  styles: []
})
// [style.marginLeft.px]="column.indentationLevel * 20"
export class ReportColumnFormComponent implements OnInit {
  reportForm: FormGroup;
  @Input() columns: any[];
  Formats: DropDownList[] = [{
    value: 'any',
    text: 'any'
  }, {
    value: 'Date yyyy-MM-dd',
    text: 'Date yyyy-MM-dd'
  }, {
    value: 'Date yyyy-MM-dd h:mm a',
    text: 'Date yyyy-MM-dd h:mm a'
  }, {
    value: 'Time h:mm',
    text: 'Time h:mm'
  }, {
    value: 'Time h:mm a',
    text: 'Time h:mm a'
  }];

  constructor(
    private datePipe: DatePipe,
    private fb: FormBuilder
  ) {
  }

  addChild(column: any): void {
    column.columns.push({
      isActive: true,
      displayOrder: 0,
      id: null,
      columnName: '',
      displayName: '',
      fDisplayName: '',
      width: 20,
      type: '',
      isVisible: true,
      fontSize: 14,
      fontWeight: '',
      textAlign: '',
      columns: [],
    });
  }

  removeColumn(column: any): void {
    const index = this.columns.indexOf(column);
    if (index !== -1) {
      this.columns.splice(index, 1);
    }
  }

  submitForm() {
  }

  ngOnInit(): void {
  }
}