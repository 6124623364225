import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpResponse,
} from "@angular/common/http";
import { BehaviorSubject, catchError, filter, mergeMap, Observable, retry, retryWhen, switchMap, take, tap, throwError, timeout, timer } from "rxjs";
import { AuthService } from "../service/auth.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorService } from "src/app/DTOs/errors/error.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private errorService: ErrorService, private router: Router, private snackBar: MatSnackBar) { }
  lang = localStorage.getItem("lang");
  retryDelay = 1000;
  maxRetryAttempts = 2;
  // refreshTokenInProgress = false;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    // add authorization header with jwt token if available
    // let currentUser = this.authenticationService.getToken();
    if (!request.url.includes('assets/i18n')) {
      if (!navigator.onLine) {
        const error = { message: 'Internet connection is not available.' };
        return throwError(error);
      }
    }
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      if (!request.url.includes('tokens/refresh')) {
        if (!request.url.includes('signup')) {
          if (!request.url.includes('activate')) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
          }
        }
      }
    }

    if (!request.headers.has('Accept')) {
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    }
    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    request = request.clone({ headers: request.headers.set('Accept-Language', localStorage.getItem("lang")) });
    request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', '*') });
    request = request.clone({ headers: request.headers.set('Access-Control-Allow-Credentials', 'true') });
    request = request.clone({
      headers: request.headers.set("Access-Control-Allow-Methods",
        "GET,POST,PUT,PATCH,DELETE,OPTIONS")
    });
    request = request.clone({ headers: request.headers.set("Access-Control-Allow-Headers", "Content-Type") });

    // if (request.url.includes('/i18n'))
    //   request = request.clone({ headers: request.headers.set('Host', 'https://api.bayanerp.com') });

    console.log(request);
    return next.handle(request).pipe(
      // timeout(10000),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const jwtToken = event.headers.get('Authorization');
          if (jwtToken) {
            console.log('JWT Token:', jwtToken);
          }
          console.log('Response Data for '+event.url, event.body);
        }
      }),
      retryWhen(errors => {
        let retryAttempts = 0;
        return errors.pipe(
          mergeMap(error => {
            if (retryAttempts++ < this.maxRetryAttempts) {
              return timer(this.retryDelay);
            }
            return throwError(error);
          })
        );
      }),
      catchError(error => {
        console.log("tamer", error);

        console.log("tamer2", error.status);

        if (error instanceof HttpErrorResponse && !request.url.includes('signin') && error.status === 0 || error.status === 401) {
          // console.clear();
          // if (error.status === 0) {
          //   return throwError(error);
          // }
          // if (error.message.includes('Access to XMLHttpRequest')) {
          //   this.
          // }
          console.log('t');
          if (request.url.includes('tokens/refresh'))
            this.authenticationService.logout().subscribe((res) => {
              if (!res.success) {
                this.router.navigate(["/authentication/signin"]);
              }
            });
          return this.handle401Error(request, next);
        }
        // if (error.status === 0) {
        //   this.showNotification(
        //     "snackbar-danger",
        //     'Internet connection is not available.',
        //     "bottom",
        //     "center",
        //     4000
        //   );
        // }
        return throwError(error);
      }));
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      console.log('request', request.url);
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authenticationService.GenerateRefreshToken(localStorage.getItem("token"), localStorage.getItem("refreshToken")).pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.data.token);
          return next.handle(this.addTokenHeader(request, token.data.token));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          console.log(this.errorService.getServerErrorMessage(err));
          // this.router.navigate(["/admin/dashboard/nopermission"]);
          this.authenticationService.logout().subscribe((res) => {
            if (!res.success) {
              this.router.navigate(["/authentication/signin"]);
            }
          });
          return throwError(err);
        })
      );
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }

  showNotification(colorName, text, placementFrom, placementAlign, duration) {
    this.snackBar.open(text, "", {
      duration: duration,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }
}


//   intercept(
//     request: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<any> {
//     // add authorization header with jwt token if available
//     // let currentUser = this.authenticationService.getToken();
//     let currentUser = JSON.parse(localStorage.getItem('currentUser'));
//     if (currentUser && currentUser.token) {
//       if (!request.url.includes('/tokens/refresh')) {
//         request = request.clone({
//           setHeaders: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         });
//       }

//       //request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
//       if (!request.headers.has('Accept')) {
//         request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
//       }
//       request = request.clone({ headers: request.headers.set('Accept-Language', localStorage.getItem("lang")) });
//       request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', '*') });
//       request = request.clone({ headers: request.headers.set('Access-Control-Allow-Credentials', 'true') });
//     }
//     console.log(request.headers);
//     // return next.handle(request)    
//     return next.handle(request).pipe(
//       catchError(async (err) => {
//         if (err.status === 0) {
//           this.authenticationService.collectFailedRequest(request);
//           if (this.refreshTokenInProgress == false) {
//             this.refreshTokenInProgress = true;
//             await this.authenticationService
//               .GenerateRefreshToken(localStorage.getItem("token"), localStorage.getItem("refreshToken"))
//               .subscribe(
//                 async (res) => {
//                   if (res) {
//                     this.refreshTokenInProgress = false;
//                     return await next.handle(request);
//                   }
//                 }), catchError(errodata => {
//                   this.refreshTokenInProgress = false;
//                   this.authenticationService.logout();
//                   return throwError(errodata)
//                 });
//           }
//         }
//         else {
//           return throwError(err);
//         }
//         // const error = err.error.message || err.statusText;
//         // return throwError(error);
//       })
//       , retry(5),
//     );
//   }
// }

export const jwtInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: JwtInterceptor,
  multi: true
};


//     const headers = new HttpHeaders('Content-Type:application/json').set('Accept-Language', this.lang);
