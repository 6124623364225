import { Pipe, PipeTransform } from '@angular/core';
import { numberToWords } from 'number-to-words';
import { toArabicWord } from 'number-to-arabic-words/dist/index-node.js';
@Pipe({
    name: 'numToWords'
})
export class NumToWordsPipe implements PipeTransform {
    transform(value: number, language: string): string {
        if (language === 'ar') {
            return toArabicWord(value);
        } else if (language === 'en') {
            return numberToWords.toWords(value).replace(/-/g, ' ');
        } else {
            return '';
        }
    }

}