<app-page-loader></app-page-loader>
<p-dialog header="{{'COMMON.DoYouNeedToContinue' | translate }}" [showHeader]="false" [(visible)]="showMessageBox"
    [closable]="false" [style]="{ width: '32rem' }">
    <div class="row">
        <div class="col-12 mt-5 mb-4 justify-content-center">
            <img src="assets/images/icons/progress.png" alt="" style="width: 100px;">
        </div>
        <div class="col-12 mt-2 mb-2 justify-content-center">
            <p style="font-size: 22px;font-weight: bold;margin: 0px;">{{'COMMON.DoYouNeedToContinue' | translate }}</p>
        </div>
        <div class="col-12 mt-4 mb-3 justify-content-center">
            <div class="">
                <button class="btn-space" (click)="continue()" mat-raised-button color="primary">{{
                    'COMMON.Continue' | translate }}</button>
                <button mat-raised-button color="warn" (click)="logout()" tabindex="-1">{{ 'COMMON.Logout' |
                    translate
                    }}</button>
            </div>
        </div>
    </div>
</p-dialog>
<router-outlet></router-outlet>