import { Component, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-bayandate-picker',
  templateUrl: './bayandate-picker.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: BayanDatePickerComponent, multi: true }
  ]
})
export class BayanDatePickerComponent implements OnInit, ControlValueAccessor {

  model = {year: 2019, month: 12, day: 1};

  innerValue: string;
  private changed = [];
  private touched = [];
  private disabled: boolean;

  @Input() id: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() calendar: 'gregorian' | 'umalqura';

  get value(): string {
    return this.innerValue;
  }
  
  set value(value: string) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changed.forEach(f => f(value));
    }
  }

  constructor() {
  }

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    this.changed.push(fn);
  }

  registerOnTouched(fn: any): void {
    this.touched.push(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: string): void {
    this.innerValue = obj;
  }
}
