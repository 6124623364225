import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: string, searchText: string): string {
        if (!searchText || !value) {
          return value;
        }
    
        const translatedSearchText = this.translateService.instant(searchText);
        const regex = new RegExp(this.escapeRegExp(translatedSearchText), 'gi');
        return value.replace(regex, match => `<span class="highlight">${match}</span>`);
      }
    
      private escapeRegExp(text: string): string {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      }
}