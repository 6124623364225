<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="clearfix">
        <!-- <img [src]='room.img' alt="avatar"> -->
        <div class="modal-about">
          <div class="font-weight-bold p-t-5 font-17 font-bold">
            <span class="font-weight-bold p-t-5 font-17 font-bold" *ngIf="lang=='ar'">{{'COMMON.Print'|translate}} {{
              tableName | translate }}</span>
            <span class="font-weight-bold p-t-5 font-17 font-bold" *ngIf="lang=='en'">{{'COMMON.Print'|translate}} {{
              tableFName | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <button id="print" type="button" mat-raised-button printSectionId="divPrintId" styleSheetFile="src/styles.scss"
        useExistingCss="false" openNewTab="true" ngxPrint>
        <img src="assets/images/icons/print.svg" alt="">
        {{ 'COMMON.Print' | translate }}
      </button>
      <!-- <button style="margin-right: 10px !important; background: inherit !important;
          box-shadow: none !important;" type="button" mat-raised-button (click)="generatePDF()">
        <mat-icon fontSet="material-icons-outlined" style="font-size: 23px;">picture_as_pdf
        </mat-icon>
      </button> -->
      <button mat-raised-button color="primary" class="mx-3" (click)="saveSettings()">
        {{'ReportBuilder.SaveSettings'|translate}}
        <mat-icon fontSet="material-icons-outlined" style="font-size: 23px;">save</mat-icon>
      </button>
      <!-- <button mat-icon-button (click)="visibleForColumns=true">
        <mat-icon fontSet="material-icons-outlined"></mat-icon>
      </button> -->
      <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon fontSet="material-icons-outlined">close</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="mat-dialog-content" style="max-height: 86vh !important;">
    <p-progressSpinner class="flex" *ngIf="!reportdata"></p-progressSpinner>
    <div *ngIf="reportdata" class="card m-4 bayan-repoer-tools">
      <div class="body">
        <div>
          <div class="row">
            <div class="col-12 mt-2 mb-0 left">
              <ul class="tools">
                <li style="width: 16rem;">
                  <p-dropdown [options]="fontFamily" optionLabel="text" optionValue="value" [filter]="false"
                    emptyFilterMessage="{{'COMMON.NoDataFound' | translate}}"
                    emptyMessage="{{'COMMON.NoDataFound' | translate}}" [showClear]="false"
                    [(ngModel)]="selectedfontFamily" [ngModelOptions]="{standalone: true}" selectedItem="SelectedGroup"
                    required="true" placeholder="{{'ReportBuilder.FontFamily' | translate}}" autoWidth="false"
                    [style]="{'width':'100%'}" class="report-font-family">
                  </p-dropdown>
                </li>

                <li>
                  <p-toggleButton [(ngModel)]="bold" onLabel="B" offLabel="B"
                    (onChange)="bold?selectedfontWeight='bold':selectedfontWeight='200'"></p-toggleButton>
                </li>
                <li>
                  <p-toggleButton class="report-text-align-center" [(ngModel)]="textAlignCenter"
                    onIcon="'pi pi-align-center'" offIcon="pi pi-align-center"
                    (onChange)="textAlignCenter?selectedtextAlign='center':selectedtextAlign='inherit'"></p-toggleButton>
                </li>
                <li>
                  <p-selectButton [options]="printLayout" [(ngModel)]="selectedprintLayout" optionLabel="text"
                    optionValue="value" (onChange)="addPrintStyles()">
                    <ng-template let-item pTemplate>
                      <mat-icon fontSet="material-icons-outlined" style="margin: -1.5px;display: flex;justify-content: center;
            align-items: center;">{{item.text}}</mat-icon>
                    </ng-template>
                  </p-selectButton>
                </li>
                <li>
                  <p-inputNumber [showButtons]="true" [(ngModel)]="fontSize" buttonLayout="horizontal"
                    inputId="horizontal" spinnerMode="horizontal" [step]="1" [min]="10" [max]="35"
                    decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" class="inputNumber">
                  </p-inputNumber>
                </li>
                <li>
                  <button mat-raised-button color="primary" (click)="this.visible = true" style="width: 100%;
                  white-space: normal;">
                    {{'COMMON.VisibleColumnsAndOrder' | translate}}
                    <mat-icon fontSet="material-icons-outlined" class="mat-icon-in-button">sort</mat-icon>
                  </button>
                </li>
                <li>
                  <button mat-raised-button color="primary" (click)="this.visibleHeaderAndFooterSetting = true" style="width: 100%;
                  white-space: normal;">
                    {{'ReportBuilder.Footer' | translate}}
                    <mat-icon fontSet="material-icons-outlined" class="mat-icon-in-button">sort</mat-icon>
                  </button>
                </li>
              </ul>
              <!-- <div class="right">
                <button mat-raised-button color="primary" (click)="saveSettings()">
                  {{'ReportBuilder.SaveSettings'|translate}}
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-2"></div>
    <div id="divPrintId" [ngStyle]="lang=='ar'?{'direction': 'rtl'}:{'direction': 'ltr'}" class="m-5">
      <div style="display: flex;
      justify-content: space-between; border-bottom: 1px solid #d6d6d6; margin-bottom: 10px;">
        <div style=" text-align: center;">
          <img [src]='"./../../../../../../../assets/images/bayan_food.png"' style="width: 170px; margin-right: 10px;"
            alt="avatar">
        </div>
        <div *ngIf="lang=='ar'" style="font-size: 30px;display: flex;align-items: center;justify-content: center;">
          {{ tableName | translate}}
        </div>
        <div *ngIf="lang=='en'" style="font-size: 30px;display: flex;align-items: center;justify-content: center;">
          {{ tableFName | translate}}
        </div>
        <div *ngIf="reportHeader">
          <p style="margin-bottom: 6px;font-weight: bold;">{{ 'AppSettings.CompanyName' | translate }}:
            <span style="font-weight: 200;">{{lang=='ar'?reportHeader.companyName:reportHeader.f_Company_Name}}</span>
          </p>
          <p style="margin-bottom: 6px;font-weight: bold;">{{ 'AppSettings.PhoneNumber' | translate }}:
            <span style="font-weight: 200;">{{reportHeader.phoneNumber}}</span>
          </p>
          <p style="margin-bottom: 6px;font-weight: bold;">{{ 'AppSettings.Address' | translate }}:
            <span style="font-weight: 200;">{{lang=='ar'?reportHeader.address:reportHeader.f_Address }}</span>
          </p>
          <p style="margin-bottom: 6px;font-weight: bold;">{{ 'COMMON.Date' | translate }}: <span
              style="font-weight: 200;">{{reportDate |
              date:'yyyy-MM-dd h:mm a'}}</span></p>
        </div>
      </div>

      <app-bayandynamic-table *ngIf="action!='details'" [columns]="selectedColumns"
        [tableHeaderAndFooter]="tableHeaderAndFooter" [data]="reportdata" [fontSize]="fontSize"
        [selectedfontWeight]="selectedfontWeight" [selectedfontFamily]="selectedfontFamily"
        [selectedtextAlign]="selectedtextAlign"></app-bayandynamic-table>
      <!-- <p-table *ngIf="action!='details'" [columns]="selectedColumns" [value]="reportdata" [resizableColumns]="true"
        styleClass="p-datatable-gridlines" class="report-table" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let column of columns" pResizableColumn pSortableColumn="{{column.columnName}}"
              style="border-bottom: 1px solid rgb(191, 201, 218);"
              [ngStyle]="{'font-size':fontSize+'px','font-family':selectedfontFamily,'text-align':selectedtextAlign}">
              {{column.displayName|translate}}
              <p-sortIcon field="{{column.columnName}}"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-columns="columns">
          <tr>
            <td *ngFor="let column of columns" [ngStyle]="{'text-align':selectedtextAlign}">
              <span [ngSwitch]="true">
                <span *ngSwitchCase="column.type=='boolean'">
                  <div *ngIf="item[column.columnName]==true"
                    [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}"
                    class="badge col-green">{{ 'COMMON.Yes' | translate }}
                  </div>
                  <div *ngIf="item[column.columnName]==false"
                    [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}"
                    class="badge col-red">{{ 'COMMON.No' | translate }}</div>
                </span>
                <span *ngSwitchCase="column.type=='date'" style="white-space: normal;"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}">{{item[column.columnName]|date:'yyyy-MM-dd'}}</span>
                <span *ngSwitchCase="column.type=='datetime'" style="white-space: normal;"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}">
                  {{item[column.columnName]|date:'yyyy-MM-dd h:mm a'}}</span>
                <span *ngSwitchCase="column.type=='enum'" style="white-space: normal;"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}">
                  <span *ngIf="item[column.columnName]!=0">{{'Enum.'+item[column.columnName]|translate}}</span>
                  <span *ngIf="item[column.columnName]==0">{{item[column.columnName]}}</span>
                </span>
                <span *ngSwitchDefault style="white-space: normal;"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}">{{item[column.columnName]}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr *ngIf="selectedColumns">
            <th [colSpan]="selectedColumns.length"
              style="color:#8080804d; width: 100%; font-weight: bolder; text-align: center; padding-top: 18px;">
              Powered with <b style="opacity: 2; color: #ff000047;">❤</b> by
              BAYAN ERP Cloud Apps
            </th>
          </tr>
        </ng-template>
      </p-table> -->

      <div *ngIf="action=='details'&&selectedColumns">
        <div class="mb-4" style="width: 100%;display: flex;
        flex-wrap: wrap;">
          <div style="display: inline-block;border: 1px solid #c9c9c9;
          padding: 8px 7px;" *ngFor="let column of getColumnsWithoutChildrenforDetailsHeader(selectedColumns)"
            [ngStyle]="{'width':column.width+'%'}">
            <span [ngSwitch]="true">
              <span *ngSwitchCase="column.type=='boolean'">
                <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                <div *ngIf="reportdata[column.columnName]==true"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}"
                  class="badge col-green">{{ 'COMMON.Yes' | translate }}
                </div>
                <div *ngIf="reportdata[column.columnName]==false"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}"
                  class="badge col-red">{{ 'COMMON.No' | translate }}</div>
              </span>
              <span *ngSwitchCase="column.type=='date'">
                <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                {{reportdata[column.columnName]|date:'yyyy-MM-dd'}}
              </span>
              <span *ngSwitchCase="column.type=='datetime'">
                <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                {{reportdata[column.columnName]|date:'yyyy-MM-dd \n h:mm a'}}
              </span>
              <span *ngSwitchCase="column.type=='time'">
                <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                {{reportdata[column.columnName]|date:'h:mm a'}}
              </span>
              <span *ngSwitchCase="column.type=='object'">
                <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                {{splitObject(reportdata,column.columnName)}}
              </span>
              <span *ngSwitchCase="column.type=='dateYear'">
                <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                {{reportdata[column.columnName]|date:'yyyy'}}
              </span>
              <span *ngSwitchCase="column.type=='enum'">
                <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                <span
                  *ngIf="reportdata[column.columnName]!=0">{{'Enum.'+reportdata[column.columnName]|translate}}</span>
                <span *ngIf="reportdata[column.columnName]==0">{{reportdata[column.columnName]}}</span>
              </span>
              <span *ngSwitchDefault>
                <span style="font-weight:bold;">{{column.displayName |translate}}</span>:
                {{reportdata[column.columnName]}}
              </span>
            </span>
          </div>
        </div>
        <div *ngFor="let column of selectedColumns">
          <!-- <h4 *ngIf="column.columns" style="font-weight: bold;"
            [ngStyle]="{'font-size':fontSize+'px','font-family':selectedfontFamily,'text-align':selectedtextAlign}">
            {{column.displayName|translate}}</h4> -->
          <app-bayandynamic-table *ngIf="column.columns.length>0" [columns]="column.columns"
            [tableHeaderAndFooter]="tableHeaderAndFooter" [tableName]="column.displayName"
            [data]="reportdata[column.columnName]" [fontSize]="fontSize" [selectedfontWeight]="selectedfontWeight"
            [selectedfontFamily]="selectedfontFamily" [selectedtextAlign]="selectedtextAlign"></app-bayandynamic-table>
        </div>
      </div>
    </div>
    <div class="p-4"></div>
  </div>
  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">

    <p-dialog header="{{'COMMON.VisibleColumnsAndOrder'|translate}}" [(visible)]="visible" [modal]="true"
      [style]="{ width: '65vw' }" [draggable]="false" [resizable]="false">

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <label class="form-card-tilte">{{'COMMON.ReportName' | translate}}</label>
          <div class="form-card">
            <div class="body row m-2">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>{{ 'COMMON.Name' | translate }}</mat-label>
                  <input matInput [(ngModel)]="tableName">
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>{{ 'COMMON.FName' | translate }}</mat-label>
                  <input matInput [(ngModel)]="tableFName">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="columnTemplate; context: { $implicit: selectedColumns,name:lang=='ar'?tableName:tableFName }"></ng-container>
    </p-dialog>
  </div>

  <ng-template #columnTemplate let-columns let-name="name">
    <p-orderList [value]="columns" [listStyle]="{'max-height':'30rem'}" header="{{ name | translate }}"
      [dragdrop]="true">
      <ng-template let-column pTemplate="item">
        <div class="product-item">
          <div class="justify-content-between">
            <h5 class="mb-2">{{column.displayName|translate}}</h5>
            <input type="text" class="p-inputtext-sm" placeholder="{{'ReportBuilder.Width'|translate}}" pInputText
              [(ngModel)]="column.width" />
            <mat-icon style="cursor: pointer;" *ngIf="column.isVisible"
              (click)="column.isVisible=false">visibility</mat-icon>
            <mat-icon style="cursor: pointer;" *ngIf="!column.isVisible"
              (click)="column.isVisible=true">visibility_off</mat-icon>
          </div>
        </div>
      </ng-template>
    </p-orderList>

    <ng-container *ngIf="checkHasChildren(columns)">
      <ng-container *ngFor="let column of getColumnsWithChildren(columns)">
        <div class="m-4">
          <ng-container
            *ngTemplateOutlet="columnTemplate; context: { $implicit: column.columns,name:column.displayName }"></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>


  <p-dialog header="{{'COMMON.VisibleColumnsAndOrder'|translate}}" [(visible)]="visibleForColumns" [modal]="true"
    [style]="{ width: '65vw' }" [draggable]="false" [resizable]="false">
    <div class="row">
      <app-report-column-form [columns]="selectedColumns"></app-report-column-form>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
        <button class="btn-space addDayButton button" type="button" mat-raised-button color="primary"
          (click)="addParent()">{{'ReportBuilder.AddColumn' | translate}}</button>
      </div>
    </div>
  </p-dialog>

  <p-dialog header="{{'ReportBuilder.Footer'|translate}}" [(visible)]="visibleHeaderAndFooterSetting" [modal]="true"
    [style]="{ width: '65vw' }" [draggable]="false" [resizable]="false">
    <div class="row">
      <p-editor [(ngModel)]="tableHeaderAndFooter.footer" [ngModelOptions]="{standalone: true}"
        [style]="{'height':'320px'}"></p-editor>
    </div>
  </p-dialog>
</div>