import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { BayanReport } from "src/app/admin/bayancomponents/bayantablereport-dialog/bayantablereport.model";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class BayanComponentsService {
  private readonly API_URL = environment.apiUrl;
  constructor(private httpClient: HttpClient) {
  }

  addBayanReport(bayanReport: BayanReport): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + '/v1/reportsetting/create', bayanReport);
  }

  updateBayanReport(id: string, bayanReport: BayanReport): any {
    return this.httpClient.put<any>(`${this.API_URL}/v1/reportsetting/update/${id}`, bayanReport);
  }

  getEditBayanReport(id: string): Observable<any> {
    return this.httpClient.get<any>(`${this.API_URL}/v1/reportsetting/getedit/${id}`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getBayanReport(reportCode: string): Observable<any> {
    return this.httpClient.get<any>(`${this.API_URL}/v1/reportsetting/getforprint/${reportCode}`);
  }

  getReportHeader(): Observable<any> {
    return this.httpClient.get<any>(`${this.API_URL}/v1/appsettings/getinvoice`);
  }
}

