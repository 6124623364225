import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-bayandynamic-table',
  template: `<p-table [columns]="columns" [value]="data" [ngStyle]="lang=='ar'?{'direction': 'rtl'}:{'direction': 'ltr'}"
        styleClass="p-datatable-gridlines" class="report-table mytable" [tableStyle]="{ 'min-width': '50rem','width': '100%' }">
        <ng-template pTemplate="header" let-columns>
          <tr [attr.colspan]="columns.length"><h4 style="font-weight: bold;" [ngStyle]="{'font-size':fontSize+'px','font-family':selectedfontFamily,'text-align':selectedtextAlign}">{{tableName|translate}}</h4></tr>
          <tr>
          <ng-container *ngFor="let column of columns">
              <ng-container *ngIf="column.columns.length==0&&column.isVisible">
                <th pSortableColumn="{{column.columnName}}" style="border-bottom: 1px solid rgb(191, 201, 218);"
                  [ngStyle]="{'width':column.width+'%','font-size':fontSize+'px','font-family':selectedfontFamily,'text-align':selectedtextAlign}">
                  {{column.displayName|translate}}
                  <p-sortIcon field="{{column.columnName}}"></p-sortIcon>
                </th>
              </ng-container>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-columns="columns" let-rowIndex="rowIndex">
          <tr>
            <td *ngFor="let column of getColumnsWithoutChildren()" [ngStyle]="{'text-align':selectedtextAlign}">
              <span [ngSwitch]="true">
                <span *ngSwitchCase="column.type=='boolean'">
                  <div *ngIf="item[column.columnName]==true"
                    [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}"
                    class="badge col-green">{{ 'COMMON.Yes' | translate }}
                  </div>
                  <div *ngIf="item[column.columnName]==false"
                    [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}"
                    class="badge col-red">{{ 'COMMON.No' | translate }}</div>
                </span>
                <span *ngSwitchCase="column.type=='date'" style="white-space: normal;"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}">{{item[column.columnName]|date:'yyyy-MM-dd'}}</span>
                <span *ngSwitchCase="column.type=='datetime'" style="white-space: normal;"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}">
                  {{item[column.columnName]|date:'yyyy-MM-dd \n h:mm a'}}</span>
                <span *ngSwitchCase="column.type=='time'" style="white-space: normal;"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}">
                  {{item[column.columnName]|date:'h:mm a'}}</span>
                <span *ngSwitchCase="column.type=='object'" style="white-space: normal;"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}">
                  {{splitObject(item,column.columnName)}}</span>
                <span *ngSwitchCase="column.type=='enum'" style="white-space: normal;"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}">
                  <span *ngIf="item[column.columnName]!=0">{{'Enum.'+item[column.columnName]|translate}}</span>
                  <span *ngIf="item[column.columnName]==0">{{item[column.columnName]}}</span>
                </span>
                <span *ngSwitchDefault style="white-space: normal;"
                  [ngStyle]="{'font-size':fontSize+'px','font-weight':selectedfontWeight,'font-family':selectedfontFamily}">{{item[column.columnName]}}</span>
              </span>
            </td>
          </tr>
          <!-- <tr *ngIf="rowIndex === (data.length - 1)">
              <th *ngFor="let col of getColumnsWithoutChildren()" [ngStyle]="{'width':col.width+'%','font-size':fontSize+'px','font-family':selectedfontFamily,'text-align':selectedtextAlign}"> 
                <span style="font-weight: bolder;" *ngIf="col.columnName=='price'||col.columnName=='cost'">
                  Total: {{getColumnsSummery(col)}}
                </span> 
              </th>
          </tr> -->
          <ng-container *ngFor="let column of getColumnsWithChildren()" class="nested-table-container">
            <tr *ngIf="item[column.columnName] && item[column.columnName].length > 0">
              <td [attr.colspan]="getColumnsWithoutChildren().length">
                  <!-- <h4 style="font-weight: bold;" [ngStyle]="{'font-size':fontSize+'px','font-family':selectedfontFamily,'text-align':selectedtextAlign}">{{column.displayName|translate}}</h4> -->
                  <app-bayandynamic-table [columns]="column.columns" [data]="item[column.columnName]" [tableName]="column.displayName" [fontSize]="fontSize" [selectedfontWeight]="selectedfontWeight"
            [selectedfontFamily]="selectedfontFamily" [selectedtextAlign]="selectedtextAlign"></app-bayandynamic-table>
              </td>
            </tr>
          </ng-container>
        </ng-template>
        <!-- <ng-template pTemplate="footer" let-columns>
          <tr *ngIf="columns&&tableHeaderAndFooter" style="position: absolute;bottom: -30px;width: 100%;display: contents;">
          <td class="ql-editor" [colSpan]="getColumnsWithoutChildren().length" *ngIf="tableHeaderAndFooter.footer">
            <span [innerHtml]="getTrustedHtmlContent()"></span>
          </td>
            <th *ngFor="let col of getColumnsWithoutChildren()" [ngStyle]="{'width':col.width+'%','padding-top':'18px'}"> 
              <span style="font-weight: bolder;" *ngIf="col.columnName=='price'||col.columnName=='cost'">
                 Total: {{getColumnsSummery(col)}}
              </span> 
            </th>
          </tr>
        </ng-template> -->


        <ng-template pTemplate="footer" let-columns>
          <tr *ngIf="columns&&tableHeaderAndFooter" style="bottom: -30px;width: 100%;display: contents;">
          <td class="ql-editor" [colSpan]="getColumnsWithoutChildren().length" *ngIf="tableHeaderAndFooter.footer">
            <span [innerHtml]="getTrustedHtmlContent()"></span>
          </td>
          </tr>
        </ng-template>

        <!-- <ng-template pTemplate="summary" style="position: absolute;bottom: -30px;width: 100%;display: contents;">
          <tr style="width: 100%;display: contents;">
              <th *ngFor="let col of getColumnsWithoutChildren()" [ngStyle]="{'width':col.width+'%','font-size':fontSize+'px','font-family':selectedfontFamily,'text-align':selectedtextAlign}"> 
                <span style="font-weight: bolder;" *ngIf="col.columnName=='price'||col.columnName=='cost'">
                  Total: {{getColumnsSummery(col)}}
                </span> 
              </th>
          </tr>
        </ng-template> -->

      </p-table>
      
  `,
  styles: [`
  td{
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
  }
  .p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa !important;
    color: #000 !important;
  }
  `]
})
export class BayanDynamicTableComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer
  ) {

  }
  @Input() data: any[];
  @Input() columns: any;
  @Input() tableName: any;
  @Input() tableHeaderAndFooter: any;
  @Input() fontSize: string;
  @Input() selectedfontWeight: string;
  @Input() selectedfontFamily: string;
  @Input() selectedtextAlign: string;
  ngOnInit(): void {

  }
  get lang(): any {
    return localStorage.getItem('lang');
  }

  getColumnsWithoutChildren() {
    return this.columns.filter((column) => column.columns.length == 0).filter((column) => column.isVisible).sort((a, b) => a.displayorder - b.displayorder);
  }

  getColumnsWithChildren() {
    return this.columns.filter((column) => column.columns.length > 0).filter((column) => column.isVisible).sort((a, b) => a.displayorder - b.displayorder);
  }

  getColumnsSummery(column: any) {
    // if (column.summery == 'total')
    return this.data.reduce((total, col) => total + col[column.columnName], 0);
  }

  getTrustedHtmlContent(): SafeHtml {
    const parser = new DOMParser();
    return this.sanitizer.bypassSecurityTrustHtml(this.tableHeaderAndFooter.footer);
  }

  splitObject(object: any, columnName: string) {
    const keys = columnName.split('.');
    let value = object;
    for (const key of keys) {
      value = value[key];
    }
    return value;
  }
}