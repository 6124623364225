import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
interface Permissions {
  claimValue: string;
  favorite: boolean
}
@Injectable({
  providedIn: "root",
})
export class PermissionService {
  private settings: any;

  private project: any;
  private salesman: any;
  private marketer: any

  permissions: Permissions[] = JSON.parse(localStorage.getItem("Permissions"));
  constructor(private http: HttpClient) {
  }

  GetUserPermission(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/roles/${id}/permissions`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  IsAuthorized(claim: string) {
    this.permissions = JSON.parse(localStorage.getItem("Permissions"));
    var a = this.permissions.find(a => a.claimValue === claim);
    if (a)
      return true;
    else
      return false;
    // return true;
  }

  IsAuthorizedGroup(claim: string[]): boolean {
    this.permissions = JSON.parse(localStorage.getItem("Permissions"));
    return claim.some(cl => this.permissions.some(permission => permission.claimValue === cl));
    // var a = Array();
    // // this.Permissions = JSON.parse(localStorage.getItem("Permissions"));
    // for (var i = 0; i < claim.length; i++) {
    //   var c = this.permissions.find(a => a.claimValue === claim[i]);
    //   if (c)
    //     a.push(true);
    //   else
    //     a.push(false);
    // }
    // if (a.indexOf(true) > -1)
    //   return true;
    // else
    //   return false;
    // // return true;
  }


  public getSettings(): any {
    return this.settings = JSON.parse(localStorage.getItem("settings"));
  }

  public getProject(): any {
    return this.project = JSON.parse(localStorage.getItem("project"));
  }
  public getSalesMan(): any {
    return this.salesman = JSON.parse(localStorage.getItem("salesman"));
  }
  public getMarketer(): any {
    return this.marketer = JSON.parse(localStorage.getItem("marketer"));
  }

  // public updateSettings(newSettings: any): void {
  //   this.settings = { ...this.settings, ...newSettings };
  //   console.log(this.settings);
  // }
}

