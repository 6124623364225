import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {
    constructor(
        private translate: TranslateService
    ) {

    }
    transform(value: any, args?: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return this.translate.instant('Enum.Justnow');
            const intervals = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        if (localStorage.getItem('lang') == 'en')
                            return counter + ' ' + this.translate.instant('Enum.' + i) + ' ' + this.translate.instant('Enum.ago') // singular (1 day ago)
                        if (localStorage.getItem('lang') == 'ar')
                            return this.translate.instant('Enum.ago') + ' ' + counter + ' ' + this.translate.instant('Enum.' + i) // singular (1 day ago)
                    } else {
                        if (localStorage.getItem('lang') == 'en')
                            return counter + ' ' + this.translate.instant('Enum.' + i) + 's ' + this.translate.instant('Enum.ago') // plural (2 days ago)
                        if (localStorage.getItem('lang') == 'ar')
                            return this.translate.instant('Enum.ago') + ' ' + counter + ' ' + this.translate.instant('Enum.' + i + 's') // plural (2 days ago)
                    }
            }
        }
        return value;
    }

}