import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { SidebarModule } from "primeng/sidebar";
import { TranslateModule } from "@ngx-translate/core";
import { ChipModule } from "primeng/chip";
import { BayanTableReportComponent } from "../admin/bayantablereport/bayantablereport.component";
import { BayanTableReportFormDialogComponent } from "../admin/bayancomponents/bayantablereport-dialog/bayantablereport-dialog.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TableModule } from 'primeng/table';
import { NgxPrintModule } from "ngx-print";
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MultiSelectModule } from 'primeng/multiselect';
import { PickListModule } from 'primeng/picklist';
import { OrderListModule } from 'primeng/orderlist';
import { DialogModule } from 'primeng/dialog';
import { BayanDatePickerComponent } from "../admin/bayancomponents/bayandate-picker/bayandate-picker.component";
import { HijriDatePickerComponent } from "../admin/bayancomponents/bayandate-picker/hijri-date-picker/hijri-date-picker.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { BayanDynamicTableComponent } from "../admin/bayancomponents/dynamic-table.component";
import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
import { MatInputModule } from "@angular/material/input";
import { BayanComponentsService } from "../core/service/bayancomponents.service";
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ReportColumnFormComponent } from "../admin/bayancomponents/bayantablereport-dialog/report-column-form.component";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { EditorModule } from 'primeng/editor';
import { UserInputComponent } from "../admin/bayancomponents/form/user-input/user-input.component";
export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
@NgModule({
  declarations: [BayanTableReportComponent, BayanTableReportFormDialogComponent, BayanDatePickerComponent, HijriDatePickerComponent, BayanDynamicTableComponent, ReportColumnFormComponent,
    UserInputComponent
  ],
  imports: [CommonModule,
    SidebarModule,
    TranslateModule,
    ChipModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    TableModule,
    NgxPrintModule,
    DropdownModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MultiSelectModule,
    PickListModule,
    OrderListModule,
    DialogModule,
    NgbModule,
    SelectButtonModule,
    ToggleButtonModule,
    SortablejsModule,
    InputTextModule,
    ProgressSpinnerModule,
    EditorModule
  ],
  exports: [BayanTableReportComponent, BayanTableReportFormDialogComponent, BayanDatePickerComponent, BayanDynamicTableComponent, ReportColumnFormComponent,
    UserInputComponent
  ],
  providers: [BayanComponentsService, DatePipe]
})
export class BayanComponenetsModule { }
