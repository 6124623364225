import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { PermissionGuard } from "./core/guard/permission.guard";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },
      {
        path: "admin",
        canActivate: [AuthGuard],
        data: {
          role: localStorage.getItem('role'),
        },
        loadChildren: () =>
          import("./admin/admin.module").then((m) => m.AdminModule),
      },
      // {
      //   path: "recepion",
      //   canActivate: [AuthGuard],
      //   data: {
      //     role: localStorage.getItem('role'),
      //   },
      //   loadChildren: () =>
      //     import("./recepion/recepion.module").then((m) => m.RecepionModule),
      // },
      {
        path: "currentuser",
        loadChildren: () =>
          import("./currentuser/currentuser.module").then((m) => m.CurrnetuserModule),
      },
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "pos",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./pos/pos.module").then(
        (m) => m.PosModule
      ),
  },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
