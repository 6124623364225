import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor(private translate: TranslateService,) { }
  ArabicWords() {
    return '^[\u0621-\u064A0-9 ]+$';
  }

  EnglishWords() {
    return '^[\u0000-\u007F ]+$';
  }

  Yemen() {
    return '^(00966|966|0)5([0-9]{8}$';
  }

  Saudi() {
    return '(00967|967|7)(7|1|3|0)([0-9]{7}$';
  }

  lang = localStorage.getItem("lang");

  ArabicNameErrorMessage(field: any) {
    if (field.hasError('required')) {
      return this.translate.instant('ValidatorMasseges.Required');
    }
    if (field.hasError('pattern')) {
      return this.translate.instant('ValidatorMasseges.ArabicName');
    }
    if (field.hasError('maxLength')) {
      return this.translate.instant('ValidatorMasseges.MaxLength');
    }
    return '';
  }

  EnglishNameErrorMessage(field: any) {
    if (field.hasError('required')) {
      return this.translate.instant('ValidatorMasseges.Required');
    }
    if (field.hasError('pattern')) {
      return this.translate.instant('ValidatorMasseges.ForeignName');
    }
    if (field.hasError('maxLength')) {
      return this.translate.instant('ValidatorMasseges.MaxLength');
    }
    return '';
  }

  EnglishTextErrorMessage(field: any) {
    if (field.hasError('required')) {
      return this.translate.instant('ValidatorMasseges.Required');
    }
    if (field.hasError('pattern')) {
      return this.translate.instant('ValidatorMasseges.EnglishText');
    }
    if (field.hasError('maxLength')) {
      return this.translate.instant('ValidatorMasseges.MaxLength');
    }
    return '';
  }

  ArabicTextErrorMessage(field: any) {
    if (field.hasError('required')) {
      return this.translate.instant('ValidatorMasseges.Required');
    }
    if (field.hasError('pattern')) {
      return this.translate.instant('ValidatorMasseges.ArabicText');
    }
    if (field.hasError('maxLength')) {
      return this.translate.instant('ValidatorMasseges.MaxLength');
    }
    return '';
  }

  PhoneErrorMessage(field: any) {
    if (field.hasError('pattern')) {
      return this.translate.instant('ValidatorMasseges.Yemen');
    }
    return '';
  }
}
