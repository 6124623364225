import { Injectable } from '@angular/core';
import { isUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }
  message: string;
  // error: any = {
  //   "type": "https://tools.ietf.org/html/rfc7231#section-6.5.1",
  //   "title": "One or more validation errors occurred.",
  //   "status": 400,
  //   "traceId": "00-7d2395ebc897273aafb45340324eca3e-53a7a76123a8d2d9-00",
  //   "errors": {
  //     "$.birthDate": [
  //       "The JSON value could not be converted to System.DateTime. Path: $.birthDate | LineNumber: 0 | BytePositionInLine: 379."
  //     ]
  //   }
  // };
  getServerErrorMessage(error): string {
    this.message = '';
    console.log(error.status);
    if (error.error instanceof ErrorEvent) {
      this.message = `Error: ${error.error.message}`;
    }
    else {
      switch (error.status) {
        case 400:
        case 409:
        case 401:
        case 402:
        case 404:
        case 422:
        case 406:
        case 500: {
          this.message = this.getMsgError(error.error)
          return this.message;
        }
        case 403: {
          return `Access Denied: ${error.message}`;
        }
        case 0: {
          return `Internet connection is not available.`;
        }
        // case 404: {
        //   return `Not Found: ${error.message}`;
        // }
        case 415: {
          return error.error.title;
        }
        default: {
          if (error.message.includes("Timeout"))
            return error.message;
          return `Unknown Server Error: ${error.message}`;
        }
      }
    }
  }

  getMsgError(error: any) {
    try {
      if (error.messages != undefined) {
        error = error.messages;
      }
    } catch (error) {

    }
    try {
      if (error.errors != undefined) {
        error = error.errors;
      }
    } catch (error) {

    }

    console.log(error);
    // if (error instanceof DioError) return error.message;
    if (error instanceof Array) {
      return error.join('\r\n').toString();
    };
    if (error instanceof Object) {
      let mapData = new Map(Object.entries(error));
      let msg = [];
      mapData.forEach((k, v) => {
        msg.unshift(v + ' ' + this.getMsgError(k));
      });
      return msg.join('\r\n').toString();
    }
    return error.toString();
  }
}


