import { Component, HostListener } from "@angular/core";
import { Event, Router, NavigationStart, NavigationEnd } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./DTOs/errors/error.service";
import { PermissionService } from "./core/service/permission.service";
import { AuthService } from "./core/service/auth.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  currentUrl: string;
  private online: boolean;
  timeout: any;
  readonly idleTime = 600000;
  readonly logoutTime = 60000;
  showMessageBox = false;
  currentRoute: string;
  constructor(
    public _router: Router,
    location: PlatformLocation,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private errorService: ErrorService,
    private permissionService: PermissionService,
    private authService: AuthService,
  ) {
    this.resetTimeout();
    this.online = navigator.onLine;
    this.currentRoute = this._router.url;
    window.addEventListener('online', this.handleOnlineStatus.bind(this));
    window.addEventListener('offline', this.handleOnlineStatus.bind(this));

    // Periodically check the connectivity status
    // timer(0, this.connectivityCheckInterval)
    //   .pipe(switchMap(() => this.checkConnectivityStatus()))
    //   .subscribe();

    this._router.events.subscribe(() => {
      this.currentRoute = this._router.url;
    });

    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });
  }

  @HostListener('document:mousemove')
  @HostListener('document:keydown')
  resetTimeout() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (!this.currentRoute.includes('authentication')) {
        this.showMessageBox = true;
        setTimeout(() => {
          if (this.showMessageBox) {
            this.showMessageBox = false;
            this.logout();
          }
        }, this.logoutTime);
      }
    }, this.idleTime);
  }

  continue() {
    this.showMessageBox = false;
    clearTimeout(this.timeout);
  }


  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this._router.navigate(["/authentication/signin"]);
      }
    });
  }

  // private handleOnlineStatus(): void {
  //   this.checkConnectivityStatus().subscribe();
  // }

  // private checkConnectivityStatus(): Observable<void> {
  //   console.log("navigator.onLine", navigator.onLine);
  //   console.log("this.online", this.online);
  //   const isOnline = navigator.onLine;

  //   // if (isOnline !== this.online) {
  //   this.online = isOnline;
  //   this.wifiConnected = false;

  //   if (isOnline) {
  //     // return this.http.get(`${environment.apiUrl}/v1/country/getdropdownlis`, { responseType: 'text' }).pipe(
  //     //   switchMap(() => {
  //     //     this.wifiConnected = true;
  //     //     console.log('wifiConnected', this.wifiConnected);
  //     //     return EMPTY;
  //     //   }),
  //     //   catchError(() => {
  //     //     this.wifiConnected = false;
  //     //     console.log('wifiConnected', this.wifiConnected);
  //     //     return EMPTY;
  //     //   })
  //     // );

  //     fetch(`${environment.apiUrl}/v1/country/getdropdownlist`)
  //       .then(response => {
  //         console.log(`Ping to 8.8.4.4 successful.`);
  //       })
  //       .catch(error => {
  //         this.showNotification(
  //           "snackbar-danger",
  //           this.errorService.getServerErrorMessage(error),
  //           "bottom",
  //           "center",
  //           4000
  //         );
  //       });

  //     // this.http.get<any>(`${environment.apiUrl}/v1/country/getdropdownlist`).then(
  //     //   () => {
  //     //     console.log(`Ping to 8.8.4.4 successful.`);
  //     //   },
  //     //   (error) => {
  //     //     this.showNotification(
  //     //       "snackbar-danger",
  //     //       this.errorService.getServerErrorMessage(error),
  //     //       "bottom",
  //     //       "center",
  //     //       4000
  //     //     );
  //     //     // console.log(`Ping to 8.8.4.4 failed. Error: ${error.message}`);
  //     //   }
  //     // );
  //   }
  //   // }

  //   return EMPTY;
  // }

  // isOnline(): boolean {
  //   return this.online;
  // }

  // isWifiConnected(): boolean {
  //   return this.wifiConnected;
  // }

  private handleOnlineStatus(): void {
    this.online = navigator.onLine;
    if (this.online) {
      this.showNotification(
        "blue-snackbar",
        'Internet connection is restored.',
        "bottom",
        "center",
        2000
      );
    }
    else {
      this.showNotification(
        "snackbar-danger",
        'Internet connection is not available.',
        "bottom",
        "center",
        4000
      );
    }
  }

  showNotification(colorName, text, placementFrom, placementAlign, duration) {
    this.snackBar.open(text, "", {
      duration: duration,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }
}
