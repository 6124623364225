import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorService } from "src/app/DTOs/errors/error.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import * as html2pdf from 'html2pdf.js';
import { DropDownList } from "src/app/DTOs/DropDownList/dropDownList";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Options } from "sortablejs";
import { BayanReport, HeaderAndFooter, ReportColumns } from "./bayantablereport.model";
import { BayanComponentsService } from "src/app/core/service/bayancomponents.service";
import { json } from "d3";

@Component({
  selector: "app-bayantablereport-dialog",
  templateUrl: "./bayantablereport-dialog.component.html",
  styles: [`
  td{
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
  }
  .p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa !important;
    color: #000 !important;
  }
  `]
})
export class BayanTableReportFormDialogComponent implements OnInit {
  action: string;
  tableName: string;
  tableFName: string;
  reportCode: string;
  reportdata: any[];
  columns: any[] = [];
  selectedColumns: any[] = [];
  loading = false;
  fontSize: number = 14;
  fontFamily: DropDownList[] = [{
    value: 'Poppins',
    text: 'Poppins'
  }, {
    value: 'Droid Arabic Kufi',
    text: 'Droid Arabic Kufi'
  }, {
    value: 'Arial',
    text: 'Arial'
  }, {
    value: 'cursive',
    text: 'cursive'
  }, {
    value: 'Helvetica',
    text: 'Helvetica'
  }, {
    value: 'sans-serif',
    text: 'sans-serif'
  }];
  selectedfontFamily: string = 'Arial';
  selectedfontWeight: string = '200';
  bold: boolean = false;
  printLayout: DropDownList[] = [{
    value: 'portrait',
    text: 'crop_portrait'
  }, {
    value: 'landscape',
    text: 'crop_landscape'
  }];
  selectedprintLayout: string = 'portrait';

  textAlign: DropDownList[] = [{
    value: 'left',
    text: 'pi pi-align-left'
  }, {
    value: 'center',
    text: 'pi pi-align-center'
  }, {
    value: 'right',
    text: 'pi pi-align-right'
  }];
  selectedtextAlign: string = localStorage.getItem('lang') == 'ar' ? 'right' : 'left';
  reportDate: Date = new Date();
  visible: boolean = false;
  visibleHeaderAndFooterSetting: boolean = false;
  visibleForColumns: boolean = false;
  Parentsoptions: Options = {
    group: 'parent',
    animation: 150,
    // onEnd: (event: any) => { this.saveSort(); },
  };
  bayanReport: BayanReport;
  isEditingTableSettings: boolean = false;
  editingId: string = null;
  textAlignCenter: boolean = false;
  reportHeader: any;
  footer: string;
  tableHeaderAndFooter: HeaderAndFooter = {
    footer: '',
    header: '',
  };
  constructor(
    public dialogRef: MatDialogRef<BayanTableReportFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private errorService: ErrorService,
    private translate: TranslateService,
    private router: Router,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private bayanComponentsService: BayanComponentsService,
  ) {
    // Set the defaults
    this.action = data.action;
    // this.selectedColumns = this.addIsVisibleRecursive(data.columns);
    console.log(this.selectedColumns);
    console.log(this.selectedColumns);
    this.reportCode = data.reportCode;
    this.getReportHeader();
    this.bayanComponentsService.getBayanReport(this.reportCode).subscribe((a) => {
      this.isEditingTableSettings = true;
      this.bayanReport = a.data;
      this.editingId = this.bayanReport.id;
      this.selectedfontFamily = this.bayanReport.fontFamily;
      this.selectedfontWeight = this.bayanReport.fontWeight;
      this.bayanReport.fontWeight == 'bold' ? this.bold = true : this.bold = false;
      this.selectedprintLayout = this.bayanReport.printLayout;
      this.addPrintStyles();
      this.bayanReport.textAlign == 'center' ? this.textAlignCenter = true : this.textAlignCenter = false;
      this.selectedtextAlign = this.bayanReport.textAlign;
      this.fontSize = this.bayanReport.fontSize;
      this.selectedColumns = JSON.parse(this.bayanReport.columnSettings);
      this.reportdata = this.data.reportdata;
      this.tableName = this.bayanReport.name;
      this.tableFName = this.bayanReport.fName;
      console.log(a.data);
    }, (error) => {
      this.selectedColumns = this.createColumns(data.columns);
      console.log(this.selectedColumns);
      if (this.action == 'details')
        this.columnWidth();
      this.addPrintStyles();
      this.tableName = data.tableName;
      this.tableFName = data.tableName;
      this.reportdata = this.data.reportdata;
    });
  }
  ngOnInit(): void {

  }

  getReportHeader() {
    this.bayanComponentsService.getReportHeader().subscribe(c => {
      this.reportHeader = c.data;
      this.tableHeaderAndFooter.footer = localStorage.getItem('lang') == 'ar' ? c.data.footer_Invoice_Message : c.data.f_Footer_Invoice_Message
      console.log(this.reportHeader);
    });
  }

  addPrintStyles(): void {
    if (this.selectedprintLayout == 'landscape')
      this.dialogRef.updateSize('1400px');
    if (this.selectedprintLayout == 'portrait')
      this.dialogRef.updateSize('1000px');
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      @media print {
        @page {
          size: ${this.selectedprintLayout};
        }
        table {
          page-break-inside: avoid;
          break-inside: avoid;
          page-break-after: always;
           thead {
             display: table-header-group;
            }
        }
      }
    `;
    document.head.appendChild(styleElement);
  }

  showNotification(colorName, text, placementFrom, placementAlign, duration) {
    this.snackBar.open(text, "", {
      duration: duration,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  visibleColumnChange() {

  }

  boldChanged() {
    if (this.bold)
      this.selectedfontWeight = 'bold';
    else
      this.selectedfontWeight = 'bold';
  }

  generatePDF(): void {
    const element = document.getElementById('divPrintId');
    const options = {
      margin: [10, 0, 17, 0],
      filename: 'converted.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: this.selectedprintLayout },
    };

    const style = document.createElement('style');
    style.innerHTML = `
    table {
      page-break-inside: auto;
    }
    
    tr {
      page-break-inside: avoid;
    }
    
    thead {
      display: table-header-group;
    }
    
    tfoot {
      display: table-footer-group;
    }`;
    document.head.appendChild(style);

    const pdfElement = html2pdf().set(options).from(element);

    pdfElement.toPdf().get('pdf').then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        const pageWidth = pdf.internal.pageSize.getWidth();
        const textWidth = pdf.getStringUnitWidth(`Page ${i} of ${totalPages}`) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const xPos = (pageWidth - textWidth) / 2;
        const yPos = pdf.internal.pageSize.getHeight() - 10;
        pdf.text(`Page ${i} of ${totalPages}`, xPos, yPos);
      }

      pdfElement.save();
    });
  }


  FormModalOpen(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  addIsVisibleRecursive(columns) {
    return columns.map(column => {
      const updatedColumn = { ...column, isVisible: true };
      if (column.columns) {
        updatedColumn.columns = this.addIsVisibleRecursive(column.columns);
      }
      return updatedColumn;
    });
  }

  getColumnsWithoutChildrenforDetailsHeader(columns: any) {
    return columns.filter((column) => column.columns.length === 0).filter((column) => column.isVisible).sort((a, b) => a.displayorder - b.displayorder);
  }

  getColumnsWithoutChildren(columns: any) {
    return columns.filter((column) => column.columns.length === 0);
  }

  getColumnsWithChildren(columns: any) {
    return columns.filter((column) => column.columns.length > 0);
  }

  checkHasChildren(columns: any) {
    var a = columns.filter((column) => column.columns.length > 0);
    if (a)
      return true;
    else
      return false;
  }

  columnVisibiltyChange() {
    console.log('tamer');
  }

  columnWidth() {
    this.selectedColumns.forEach((item) => {
      if (item.columns.length == 0) {
        if (item.width == undefined)
          item.width = 50;
      }
    });
  }

  saveSettings() {
    this.bayanReport = {
      id: this.editingId,
      reportCode: this.reportCode,
      name: this.tableName,
      fName: this.tableFName,
      fontSize: this.fontSize,
      fontFamily: this.selectedfontFamily,
      printLayout: this.selectedprintLayout,
      fontWeight: this.selectedfontWeight,
      textAlign: this.selectedtextAlign,
      columnSettings: JSON.stringify(this.createColumns(this.selectedColumns))
    }
    if (this.isEditingTableSettings) {
      this.bayanComponentsService.updateBayanReport(this.editingId, this.bayanReport).subscribe(data => {
        this.showNotification(
          "blue-snackbar",
          data.messages[0],
          "bottom",
          "center",
          2000
        );
      },
        (err) => {
          this.showNotification(
            "snackbar-danger",
            this.errorService.getServerErrorMessage(err),
            "bottom",
            "center",
            4000
          );
        }
      );
    }
    else {
      this.bayanComponentsService.addBayanReport(this.bayanReport).subscribe(data => {
        this.showNotification(
          "snackbar-success",
          data.messages[0],
          "bottom",
          "center",
          2000
        );
      },
        (err) => {
          this.showNotification(
            "snackbar-danger",
            this.errorService.getServerErrorMessage(err),
            "bottom",
            "center",
            4000
          );
        }
      );
    }
    console.log(this.bayanReport);
  }

  createColumns(columnsData: any[]): ReportColumns[] {
    return columnsData.map((columnData: any) => {
      const column: ReportColumns = {
        isActive: columnData.isActive,
        displayOrder: columnData.displayOrder,
        id: columnData.id,
        columnName: columnData.columnName,
        displayName: columnData.displayName,
        fDisplayName: columnData.fDisplayName,
        width: columnData.width,
        type: columnData.type,
        isVisible: columnData.isVisible == undefined ? true : columnData.isVisible,
        fontSize: columnData.fontSize,
        fontWeight: columnData.fontWeight,
        textAlign: columnData.textAlign,
        columns: columnData.columns ? this.createColumns(columnData.columns) : [],
      };
      return column;
    });
  }

  get lang(): any {
    return localStorage.getItem('lang');
  }

  addParent() {
    this.selectedColumns.push({
      isActive: true,
      displayOrder: 0,
      id: null,
      columnName: '',
      displayName: '',
      fDisplayName: '',
      width: 20,
      type: '',
      isVisible: true,
      fontSize: 14,
      fontWeight: '',
      textAlign: '',
      columns: [],
    })
  }

  splitObject(object: any, columnName: string) {
    const keys = columnName.split('.');
    let value = object;
    for (const key of keys) {
      value = value[key];
    }
    return value;
  }
}
