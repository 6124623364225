import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from "@angular/core";
import { FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DropDownList } from "src/app/DTOs/DropDownList/dropDownList";
@Component({
  selector: "app-user-input",
  templateUrl: "./user-input.component.html",
  styleUrls: ["./user-input.component.sass"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserInputComponent),
      multi: true
    }
  ]
})
export class UserInputComponent implements OnInit {
  // userDropDownList: DropDownList[];
  // @Input() value: any;
  // @Output() valueChange = new EventEmitter<any>();
  // control: FormControl = new FormControl();
  // @Input() myoptions: DropDownList;
  // @Output() modelChange = new EventEmitter<any>();
  @Input() options: DropDownList[]; // Input property to pass the options to the dropdown
  @Input() value: any; // Input property for the selected value
  @Output() valueChange = new EventEmitter<any>();
  onChange: any = () => {};
  onTouched: any = () => {};
  constructor() {
  }

  ngOnInit(): void {
    console.log(this.options);
  }

  // onChange(event) {
  //   this.modelChange.emit(event);
  // }
  onValueChange(newValue: any) {
    console.log("tamer",newValue);
    this.value = newValue.value;
    this.onChange(newValue.value);
    this.onTouched();
    this.valueChange.emit(newValue.value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // setDisabledState(isDisabled: boolean): void {
  //   this.disabled = isDisabled;
  // }

  // onInputChange(newValue: string) {
  //   this.value = newValue;
  //   this.onChange(newValue);
  //   this.onTouched();
  // }
}
