import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { MaterialModule } from "./material.module";
import { NumToWordsPipe } from "src/app/shared/number-to-words.pipe";
import { BayanComponenetsModule } from "./bayancomponents.module";
import { HighlightPipe } from "./highlight.pipe";
@NgModule({
  declarations: [NumToWordsPipe, HighlightPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    BayanComponenetsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    NumToWordsPipe,
    HighlightPipe,
    BayanComponenetsModule
  ],
})
export class SharedModule { }
